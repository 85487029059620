import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { cookie } from '@tager/web-core';

import downloadIcon from '@/assets/svg/download-apk.svg';
import CloseIcon from '@/assets/svg/close-24dp-white.svg';
import { colors } from '@/constants/theme';
import { googleEvent } from '@/utils/events';
import useSettingItem from '@/hooks/useSettingItem';

import { useLink } from './hooks';

function DownloadApkButton() {
  const [isOpen, setIsOpen] = useState(false);
  const closeGoogleEvent = useSettingItem(
    'CLICK_CLOSE_MOBILE_BANNER_GOOGLE_EVENT'
  );
  const downloadGoogleEvent = useSettingItem(
    'CLICK_DOWNLOAD_MOBILE_BANNER_GOOGLE_EVENT'
  );

  const handleClosePopup = () => {
    setIsOpen(false);
    cookie.set('downloadApkBanner', 'true', undefined, 7);

    if (closeGoogleEvent) {
      googleEvent(closeGoogleEvent);
    }
  };
  const handleDownloadClick = () => {
    setIsOpen(false);
    cookie.set('downloadApkBanner', 'true');

    if (downloadGoogleEvent) {
      googleEvent(downloadGoogleEvent);
    }
  };

  const storeLink = useLink();

  useEffect(() => {
    const lastClosedDate = cookie.get('downloadApkBanner');

    if (lastClosedDate) {
      return;
    }

    if (storeLink) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [storeLink]);

  return storeLink && isOpen ? (
    <Component>
      <Link href={storeLink} target="_blank" onClick={handleDownloadClick}>
        <DownloadIcon />
        <Text>приложение</Text>
      </Link>
      <CloseButton onClick={handleClosePopup}>
        <CloseIcon />
      </CloseButton>
    </Component>
  ) : null;
}

export default DownloadApkButton;

const Component = styled.div`
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;

  display: flex;
  align-items: center;

  border-radius: 50px;
  background: ${colors.blue1000};
`;

const Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  gap: 11px;

  max-width: 170px;
  width: 100%;

  padding: 10px 20px 10px 23px;

  &:after {
    content: '';
    position: absolute;
    right: -17px;

    width: 34px;
    height: 1px;
    background: ${colors.white_alpha40};
    transform: rotate(90deg);
  }
`;

const DownloadIcon = styled(downloadIcon)``;

const CloseButton = styled.button`
  width: 55px;
  height: 40px;
  padding: 8px 10px 8px 5px;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${colors.white};
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;
