import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import YearIcon from '@/assets/svg/year.svg';
import car from '@/assets/images/car.png';
import carMobile from '@/assets/images/car-mobile.png';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function BannerNewWebsite() {
  const isTablet = useMedia('(max-width:1259px)');

  const image = isTablet ? carMobile : car;

  return (
    <Component>
      <Link to="https://new25.atlantm.by/" target="_blank">
        <YearWrapper>
          <YearIcon />
        </YearWrapper>

        <TextWrapper>
          <Text>Готовимся к большому переезду!</Text>
          <Text>Протестируйте новую версию сайта вместе с нами.</Text>
        </TextWrapper>

        <Image src={image} />
      </Link>
    </Component>
  );
}

export default BannerNewWebsite;

const Component = styled.div`
  position: relative;
  height: 54px;
  background: ${colors.blueGradient200}, ${colors.purpleGradient200};

  ${media.tablet(css`
    position: sticky;
    z-index: 10000;
    top: 0;
  `)}
`;

const YearWrapper = styled.div`
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);

  ${media.tablet(css`
    svg {
      max-width: 51px;
      max-height: 21px;
    }

    transform: translateY(0);
    right: 21px;
    left: auto;
    top: 4px;
  `)}
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 54px;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${media.tablet(css`
    flex-direction: column;
    gap: 2px;
  `)}
`;

const Text = styled.p`
  color: ${colors.black}
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  
  ${media.tablet(css`
    font-size: 10px;
    line-height: 12px;
  `)}
`;
